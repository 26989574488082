<template>
  <div class="info">
    <div class="info_banner">
      <img :src="infoData.apparatusFileId" />
      <div class="banner_text">
        <div class="mask"></div>
        <div class="textCntanier">
          <div class="title">{{ infoData.apparatusType }}</div>
          <div class="text">{{ infoData.apparatusName }}</div>
          <div class="box">
            <div class="box_text"></div>
          </div>
        </div>
      </div>
      <div class="floatbanner">
        <div class="floatbanner_l">
          <div class="floatTile left">
            <div class="headerTitle">
              优安众测： {{ infoData.apparatusType
              }}{{ infoData.apparatusName }}
            </div>
          </div>
          <div class="floatTable">
            <div class="tabletitle title">数量：100</div>
            <div class="tabletitle">
              <!-- <div class="score"> -->
              <div class="score_num">{{ infoData.applyNum }}</div>

              <!-- </div> -->
            </div>
            <div class="tabletitle title">
              申领： {{ infoData.condition }}积分
            </div>
            <div class="tabletitle">
              <div class="score_text">人已申请</div>
            </div>
            <div class="tabletitle title">
              市场价：{{ infoData.apparatusPrice }}元
            </div>
            <div class="tabletitle">
              <div class="score_text">热度： {{ infoData.hots }}</div>
            </div>
          </div>
        </div>
        <div class="floatbanner_r">
          <div class="tabletitle padding-r">
            <div class="score_text">距离申请结束：</div>
          </div>
          <div class="tabletitle padding-r">
            <div class="score_text">
              <span class="red">{{ day }} </span>天
              <span class="red">{{ hours }} </span>时
              <span class="red">{{ minute }} </span>分
              <span class="red">{{ scend }} </span>秒
            </div>
          </div>
          <div class="tabletitle padding-r">
            <el-button
              type="primary"
              
              round
              @click="to()"
              >{{buttonTile}}</el-button
            >
          </div>
        </div>

        <!-- <div class="button">
          <el-button type="primary" round>参数对比</el-button>
          <el-button round>点评对比</el-button>
        </div> -->
      </div>
    </div>
    <div class="middleBar">
      <div class="barBox">
        <div class="barBox_l">
          <el-avatar :size="60">1</el-avatar>
        </div>
        <div class="barBox_r">
          <p class="title">产品申请</p>
          <p>点击立即申请按钮，即可参与活动</p>
        </div>
      </div>
      <div class="barBox">
        <div class="barBox_l">
          <el-avatar :size="60">2</el-avatar>
        </div>
        <div class="barBox_r">
          <p class="title">名单确认</p>
          <p>公布申请成功名单，相关用户需 及时确认</p>
        </div>
      </div>
      <div class="barBox">
        <div class="barBox_l">
          <el-avatar :size="60">3</el-avatar>
        </div>
        <div class="barBox_r">
          <p class="title">收货体验</p>
          <p>名单确认后寄送产品，供用户体验</p>
        </div>
      </div>
      <div class="barBox">
        <div class="barBox_l">
          <el-avatar :size="60">4</el-avatar>
        </div>
        <div class="barBox_r">
          <p class="title">报告提交</p>
          <p>申请成功者需在规定时限内提交众测报告</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_l">
        <div class="conent_nav">
          <div
            class="nav-title"
            @click="chose(item)"
            v-for="item in contentNav"
            :key="item.name"
          >
            <span :class="{ click: item.isClick }">{{ item.name }}</span>
          </div>
        </div>
        <div class="content_text">
          <div v-html="infoData.detail"></div>
          <div class="content_text-bottom">
            <div class="cl">
              <i class="iconfont icon-heart"></i>
            </div>
            <p>0人已赞</p>
            <div class="content_text-bottom-user">
              <el-avatar
                v-for="(item, index) in commentProsUser"
                :key="index"
                :size="40"
                src=""
              ></el-avatar>
              <i
                class="iconfont icon-shengluehao"
                v-if="commentProsUser.length > 4"
              ></i>
            </div>
          </div>
        </div>
        <div class="inputText">
          <div class="inputText_header">
            评论 <span>({{ commentList.length }})</span>
          </div>
          <div class="inputText_input">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="placeholder"
              v-model="comment"
              :disabled="userInfo == ''"
              resize="none"
            >
            </el-input>
            <div class="inputmsg" v-if="userInfo == ''">
              <p>请<span class="roge" @click="wxlogin">登录</span>后发表评论</p>
            </div>
            <div class="inputbtn">
              <el-button
                type="primary"
                @click="addNewsComment()"
                v-if="userInfo != ''"
              >
                发布评论
              </el-button>
            </div>
          </div>
          <div class="inputText_header">最新评论</div>
          <div class="userText_box">
            <div
              class="userText"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <div class="userimg">
                <el-avatar :src="item.memberIcon" :size="60"></el-avatar>
              </div>
              <div class="username">{{ item.memberName }}</div>
              <div class="usercontent">
                {{ item.discuss }}
              </div>
              <div class="userTimer">
                <p>
                  <span>{{ item.time }}</span>
                </p>
                <div class="pros">
                  <span
                    class="ic"
                    @click="isLikes(item.discussPros, 1, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzan"></i>
                    {{ item.discussPros }}
                  </span>
                  <span
                    class="ic"
                    @click="isLikes(item.discussCons, 2, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzantubiao"></i>
                    {{ item.discussCons }}
                  </span>
                </div>
              </div>
              <div class="userText" v-if="item.child">
                <div class="userimg">
                  <el-avatar :size="60"></el-avatar>
                </div>
                <div class="username">{{ item.memberName }}</div>
                <div class="usercontent">
                  {{ item.comment }}
                </div>
                <div class="userTimer">
                  <p>
                    <span>{{ item.createTime }}</span>
                  </p>
                  <div class="pros">
                    <span class="ic">
                      <i class="iconfont icon-pinglundianzan"></i>
                      {{ item.commentPros }}
                    </span>
                    <span class="ic">
                      <i class="iconfont icon-pinglundianzantubiao"></i>
                      {{ item.commentCons }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="userText_end">
              <p>评论已加载完毕～</p>
            </div>
            <div class="user_menu">
              <span class="box returnTop" @click="returnTop">
                <i class="iconfont icon-fanhuidingbutop"></i>
              </span>
              <span class="box star" @click="shoucang">
                <span class="icon_r">{{ infoData.collectNum }}</span>
                <i class="iconfont icon-shoucang"></i>
              </span>
              <span class="box msg">
                <i class="iconfont icon-pinglun"></i>
              </span>
              <span class="box heart" @click="operate">
                <i class="iconfont icon-dianzan"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="content_r">
        <div class="topinfo__r_contanier">
          <div class="topinfo__r_header">
            <span>热门众测</span>
          </div>
          <div class="topinfo__r_content">
            <div v-for="(item, index) in bannerList" :key="index">
              <div
                class="content-img"
                @click="toInfo('/check/product/info', item.id)"
              >
                <div class="box2">
                  <div class="imgBox">
                    <img
                      v-if="item.apparatusFileId"
                      :src="item.apparatusFileId"
                      alt=""
                    />
                    <img
                      v-if="!item.apparatusFileId"
                      src="../assets/test.png"
                      alt=""
                    />
                  </div>

                  <div class="content-title2">
                    {{ item.apparatusName }}
                  </div>
                  <div class="content-time">
                    <span>数量:{{ item.quantify }}</span
                    ><span>人数:{{ item.hots }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="topinfo__r_content">
            <div class="content-box"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      textarea2: "",
      infoData: {},
      commentList: [],
      bannerList: [],
      placeholder: "",
      comment: "",
      commentProsUser: [],
      day: "",
      hours: "",
      minute: "",
      scend: "",
      contentNav: [
        {
          name: "众测详情",
          isClick: true,
        },
        {
          name: "申请名单",
          isClick: false,
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    token(){
      return this.$store.state.token
    },
    buttonTile(){
      return this.infoData.isApply ? "已申请" : "立即申请";
    }
  },
  watch: {
    userInfo(n) {
      this.placeholder = n == "" ? "" : "请输入内容";
    },
    $route(to, from) {

      this.assessInfo();

      this.indexTopAssess();
      this.getAssessDiscuss();
    },
  },
  created() {
    this.assessInfo();

    this.indexTopAssess();
    this.getAssessDiscuss();
  },
  mounted() {},
  methods: {
    wxlogin() {
      this.$store.commit("wxLogin", true);
    },
    to() {
      if(!this.token){
        this.$message({ message: "请先登录", type: "warning",duration:1200 });
        return;
      }
      if(this.day == 0 && this.hours == 0 && this.minute == 0 && this.scend == 0){
        this.$message({ message: "已过期", type: "warning",duration:1200 });
        return
      }
      if(this.infoData.isApply){
        this.$message({ message: "已申请", type: "warning",duration:1200  });
      }
      this.$router.push(`/check/apply/${this.$route.params.id}`);
    },
    toInfo(path, id) {
      this.$router.push(`${path}/${id}`);
    },
    isLikes(commentCount, islike, id) {
      let data = {
        type: "comment",
        operate: islike == 1 ? "pros" : "cons",
        id,
      };

      api.operate(data).then((res) => {
        let result = res.data.data;
        this.commentList.forEach((item) => {
          if (item.commentId == result.commentId) {
            item.opinionPros = result.opinionPros;
            item.opinionCons = result.opinionCons;
          }
        });
      });
    },
    indexTopAssess() {
      api.indexTopAssess().then((res) => {
        // let newList = [];
        // let list = res.data.data.data;
        // list.forEach((item) => {
        //   let data = {
        //     id:item.apparatusId,
        //     time: "",
        //     url: item.apparatusFileId,
        //     newsTitle: item.apparatusName,
        //     quantify:item.quantify,
        //     condition:item.condition,
        //     order:item.order,
        //   };
        //   newList.push(data);
        // });
        this.bannerList = res.data.data.data;
        console.log(this.bannerList);
      });
    },
    getAssessDiscuss() {
      let data = {
        assessId: this.$route.params.id,
      };
      check.getAssessDiscuss(data).then((res) => {
        let data = res.data.data.data;
        data.forEach((item) => {
          item.time = item.createTime;
        });
        let list = this.Time(data);
        this.commentList = list;
      });
    },
    assessInfo() {
      let data = {
        assessId: this.$route.params.id,
      };
      check.assessInfo(data).then((res) => {
        this.infoData = res.data.data;
        this.getDate();
      });
    },
    getDate() {
      let _self = this;
      let endTime = new Date(this.infoData.endTime);
      let nowTime = new Date();
      let time = endTime.getTime() - nowTime.getTime();

      let day = Math.floor(time / 1000 / 60 / 60 / 24);
      let hours = Math.floor((time / 1000 / 60 / 60) % 24);
      let minute = Math.floor((time / 1000 / 60) % 60);
      let scend = Math.floor((time / 1000) % 60);
      if (day <= 0 && hours <= 0 && minute <= 0 && scend <= 0) {
        this.day = 0;
        this.hours = 0;
        this.minute = 0;
        this.scend = 0;
        return;
      }
      this.day = day;
      this.hours = hours;
      this.minute = minute;
      this.scend = scend;
      setTimeout(function () {
        _self.getDate();
      }, 1000);
    },
    addNewsComment() {
      let data = {
        assessId: this.$route.params.id,
        discuss: this.comment,
      };
      check.addAssessDiscuss(data).then((res) => {
        this.comment = "";
        this.getAssessDiscuss();
      });
    },
    shoucang() {
      let data = {
        type: "assess",
        id: this.infoData.id,
      };
      api.collectAdd(data).then((res) => {
        if (res.msg == "success") {
          this.$message("已收藏");
        }
      });
    },
    operate() {
      let data = {};
      // api.operate()
    },
    returnTop() {
      document.querySelector(".info").scrollIntoView({ behavior: "smooth" });
    },
    chose(item) {
      this.contentNav.forEach((i) => {
        i.isClick = false;
      });
      item.isClick = true;
      this.contentNav.splice(this.contentNav.length);
    },
  },
};
</script>
<style lang="scss" >
.barBox {
  .el-avatar {
    font-size: 27px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    background: #ededed;
  }
}
.user {
  .el-avatar {
    margin: 0 5px;
  }
}
.inputText_input {
  .el-textarea {
    .el-textarea__inner {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      background: #f2f2f2;
      outline: none;
    }
  }
  div {
    text-align: right;
    .el-button {
      // margin: 25px 0;
    }
  }
}
.inputbtn {
  position: absolute;

  right: 13px;
  bottom: 13px;
  .el-button {
    margin: 0;
    background: #e6e6e6;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 9px 13px;
    border: none;
    border-radius: 3px 3px 3px 3px;
  }
}
.tabletitle {
  .el-button {
    width: 205px;
    height: 59px;
    border-radius: 65px;
    box-shadow: 0px 11px 25px 1px
      rgba(2.9999998211860657, 147.9999303817749, 255, 0.33000001311302185);
    font-size: 21px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.info {
  .info_banner {
    // height: 545px;
    position: relative;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
    .banner_text {
      top: 0;
      width: 245px;
      height: 100%;
      position: absolute;
      left: 0;
      .mask {
        width: 100%;
        height: 100%;
        background: #384148;
        opacity: 0.5;
      }
      .textCntanier {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        .title {
          font-size: 35px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .text {
          font-size: 32px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          width: 68px;
          text-align: left;
          margin: 0 auto;
        }
        .box {
          width: 133px;
          padding: 12px 27px;
          border: 1px solid #ffffff;
          margin: 0 auto;
          font-size: 27px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .floatbanner {
      //   width: 632px;
      padding: 32px 88px;
      //   padding-bottom: 32px;
      //   height: 304px;
      position: absolute;
      bottom: -107px;
      left: 50%;
      transform: translateX(-50%);
      background: #ffffff;
      display: grid;
      grid-template-columns: auto 244px;
      grid-template-rows: auto;
      grid-column-gap: 160px;

      .floatbanner_r {
        display: flex;
        flex-direction: column;
      }
      .red {
        font-size: 27px;
        font-family: Helvetica-Oblique, Helvetica;
        font-weight: normal;
        color: #ff5c5c;
      }
      .floatTile {
        font-size: 27px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;

        &.left {
          min-width: 300px;
        }
        .headerTitle {
          min-width: 300px;
        }
      }
      .floatTable {
        display: grid;
        // padding: 0 35px;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
        grid-gap: 10px 20px;
        align-items: center;
      }
      .tabletitle {
        &.title {
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          padding: 7.5px 0;
          // border-right: 1px solid #f0f0f0;
        }
        &.padding-r {
          // padding-right: 28px;
          &:nth-child(1) {
            margin-bottom: 25px;
          }
          &:nth-child(2) {
            margin-bottom: 35px;
          }
        }
        .score_num {
          font-size: 48px;
          font-family: Helvetica-Oblique, Helvetica;
          font-weight: normal;
          color: #ff5c5c;
        }
        .score_text {
          font-size: 19px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        &.col {
          grid-row-start: span 6;
          .score {
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
      }
      .button {
        display: flex;
        margin-top: 25px;
        justify-content: center;
      }
    }
  }
  .middleBar {
    margin-top: 157px;
    margin-bottom: 40px;
    display: flex;
    .barBox {
      display: flex;
      padding-right: 40px;
      .barBox_r {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #c9c9c9;
        }
        .title {
          font-size: 19px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .content_l {
      width: 800px;
      .conent_nav {
        display: flex;
        padding: 11px 20px;
        margin-bottom: 35px;
        .nav-title {
          padding: 12px 10px;
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          span {
            position: relative;
            &.click {
              &::after {
                display: block;
                content: " ";
                width: 94px;
                height: 5px;
                background: #0394ff;
                border-radius: 0px 0px 0px 0px;
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
      .content_text {
        img {
          width: 100%;
        }
        font-size: 21px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        p {
          margin: 15px 0;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .text-title {
          color: #000000;
          font-weight: bold;
        }
        .content_text-bottom {
          padding: 50px;
          border-bottom: 1px solid #f0f0f0;
          text-align: center;
          .content_text-bottom-user {
            display: flex;
            justify-content: center;
            span {
              margin: 0 13px;
            }
            i {
              font-size: 40px;
              color: #c4c4c4;
            }
          }
          .cl {
            margin: 0 auto;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: #ced3db;
            border-radius: 50%;
            box-shadow: 0px 5px 21px 1px
              rgba(
                167.00000524520874,
                201.99998795986176,
                255,
                0.5699999928474426
              );
            &:hover {
              background: #f8a37f;
              box-shadow: 0px 5px 21px 1px
                rgba(248, 163, 127, 0.5699999928474426);
            }
            i {
              font-size: 32px;
              color: #fff;
            }
          }

          .user {
            width: 250px;
            margin: 0 auto;
          }
        }
      }
    }
    .content_r {
      width: 360px;
      .topinfo__r_contanier {
        .topinfo__r_header {
          padding: 10px 19px;
          span {
            padding: 5px 0;
            border-bottom: 3px solid #0092ff;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0394ff;
          }
        }
        .topinfo__r_content {
          .content-img {
            padding: 12px 13px;
            &:hover {
              background: #fff;
              box-shadow: 0px 5px 21px 1px
                rgba(
                  45.00000111758709,
                  63.000000044703484,
                  86.00000247359276,
                  0.17000000178813934
                );
            }
            &.top1 {
            }
            .box {
              position: relative;
              img {
                width: 100%;
                border-radius: 7px 7px 0 0;
              }
            }
            .box2 {
              display: grid;
              grid-template-columns: 133px auto;
              grid-template-rows: auto auto;
              grid-column-gap: 15px;
              grid-row-gap: 15px;
              .imgBox {
                grid-row-start: 1;
                grid-row-end: 3;
                img {
                  width: 100%;
                  border-radius: 7px;
                }
              }

              .content-title2 {
                font-size: 19px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #2d3f56;
              }
              .content-time {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                font-size: 16px;
                font-family: Helvetica-Regular, Helvetica;
                font-weight: 400;
                color: #b1b1b1;
              }
            }
          }

          .content-title {
            &.top1 {
              width: 217px;
              position: absolute;
              padding: 13px 21px;
              bottom: 13px;
              left: 50%;
              transform: translateX(-50%);
              border: 1px solid #ffffff;
              font-size: 19px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .inputText {
    margin-top: 53px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    .inputText_input {
      position: relative;
    }
    .inputmsg {
      position: absolute;
      left: 33px;
      top: 50%;
      transform: translateY(-50%);
      color: #888888;
      font-size: 24px;
      font-family: PingFang SC-Heavy;
      font-weight: 400;

      span {
        cursor: pointer;
        color: #0394ff;
        font-family: PingFang SC-Regular;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .inputText_header {
      margin: 50px 0;
      color: #333;
      span {
        color: #999999;
      }
    }
    .userText_box {
      position: relative;
    }
    .userText {
      display: grid;
      grid-template-columns: 80px auto;
      grid-template-rows: 60px auto auto;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 60px;
      .userimg {
        grid-row-start: 1;
        grid-row-end: 4;
      }
      .userTimer {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #bac0cf;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 35px;
        .pros {
          width: 135px;
          display: flex;
          justify-content: space-between;
          .ic {
            cursor: pointer;
            i {
              font-size: 15px;
              color: #bac0cf;
            }
          }
        }
      }
    }
    .userText_end {
      padding: 63px 0;
      font-size: 19px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }
    .user_menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: -80px;
      bottom: 52px;

      .box {
        display: block;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        // border: 1px solid #cee0ea;
        position: relative;
        margin-top: 24px;
        i {
          font-size: 61px;
          color: #cee0ea;
        }
        .icon_r {
          position: absolute;
          right: 0;
          display: block;
          width: 27px;
          height: 27px;
          line-height: 27px;
          text-align: center;
          background: #ff2e2e;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #fbfbfb;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>